//统一的header
import React, { Component } from 'react';
import { Header} from 'antd/lib/layout/layout';
import './index.css'
import {connect} from 'react-redux'
import { Menu ,Image,Col, Row,Card,Divider,Typography,Button} from 'antd';

import {Link} from 'react-router-dom'
import {
  TeamOutlined,
  ProjectOutlined,
  FundViewOutlined,
  HomeOutlined,
  CoffeeOutlined,
  ExperimentOutlined,
  TwitterOutlined,
  MailOutlined,
  PushpinOutlined,
  ContactsOutlined,
  ReadOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
const { Title } = Typography;
const { SubMenu } = Menu;
const menuItems = [
  {
    key: '1',
    icon: <HomeOutlined />,
    label: 'HOME',
    link: '/home',
  },
  {
    key: '2',
    icon: <ReadOutlined />,
    label: 'ABOUT AGIST',
    link: '/about',
  },
  {
    key: '3',
    icon: <TeamOutlined />,
    label: 'MEMBERSHIP',
    link: '/membership',
  },
  {
    key: 'sub1',
    icon: <CoffeeOutlined />,
    label: 'Conferences',
    items: [
      { key: 'C2024', label: 'ICCSI 2024', link: '/iccsi2024/' },
      { key: 'C2023', label: 'ICCSI 2023', link: '/iccsi2023/' },
      { key: 'C2022', label: 'ICCSI 2022', link: '/iccsi2022/' },
      { key: 'C2021', label: 'ICCSI 2021', link: '/iccsi2021/' },
    ],
  },
  {
    key: 'sub2',
    icon: <ExperimentOutlined />,
    label: 'Workshops and Training',
    items: [
      { key: 'WAT2024', label: '2024 AUTUMN SCHOOL', link: '/iccsi2024/autumnSchoolCFP.html' },
      { key: 'WAT2023', label: '2023 AUTUMN SCHOOL', link: '/iccsi2023/autumnSchoolCFP.html' },
      { key: 'WAT2022', label: '2022 AUTUMN SCHOOL', link: '/iccsi2022/autumnSchoolCFP.html' },
      { key: 'WAT2021', label: '2021 WINTER SCHOOL', link: '/iccsi2021/winterSchoolCFP.html' },
    ],
  },
  {
    key: 'sub3',
    icon: <PushpinOutlined />,
    label: 'Publications',
    items: [
      { key: 'CPSI', label: 'Journal of Cyber-Physical-Social Intelligence', link: 'http://cpsi.agist.org' },
      { key: 'JOCACAC', label: 'Journal of Command and Control', link: 'http://journalcc.net' },
    ],
  },
  {
    key: '4',
    icon: <ProjectOutlined />,
    label: 'NEWS',
    link: '/news',
  },
  {
    key: '5',
    icon: <ContactsOutlined />,
    label: 'CONTACTS',
    link: '/contact',
  },
];
export default function UHeader() {

  return (
    <Header  style={{ padding: 0 ,height:200 }}>
      <div className='card3'>

      
      <div className='card1'>
      <Row align="middle">
      <Col span={20}><Image
          preview={false}
          width={300}
          src={require('../../imgs/AGIST.png')}
        /> </Col>
      <Col span={1} >
      <a href="mailto:info@agist.org">
        <Button type="dashed" shape="circle" icon={<MailOutlined />} /></a>
        
        </Col>
        <Col span={1} >
        <a href="https://twitter.com/DaoAgist">
        <Button type="dashed" shape="circle" icon={<TwitterOutlined />} />
        </a>
      </Col>
      <Col span={2} >
      <Link to="/membership">
        <Button type="dashed"  icon={<UsergroupAddOutlined />} >JOIN AGIST</Button>
        </Link>
      </Col>
    </Row>
      
        
      </div>
      </div>
      <div className='card2'>
      <Menu className="ant-layout-header2" mode="horizontal"  defaultSelectedKeys="1" >
      
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <Link to="/home">HOME</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<ReadOutlined />}>
          <Link to="/about">ABOUT AGIST</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<TeamOutlined />}>
        <Link to="/membership">MEMBERSHIP</Link>
        </Menu.Item>
        <SubMenu key="sub1" icon={<CoffeeOutlined />} title="Conferences">
          <Menu.Item key="C2024" icon={<CoffeeOutlined />}><a href="/iccsi2024/">ICCSI 2024</a></Menu.Item>
          <Menu.Item key="C2023" icon={<CoffeeOutlined />}><a href="/iccsi2023/">ICCSI 2023</a></Menu.Item>
          <Menu.Item key="C2022" icon={<CoffeeOutlined />}><a href="/iccsi2022/">ICCSI 2022</a></Menu.Item>
          <Menu.Item key="C2021" icon={<CoffeeOutlined />}><a href="/iccsi2021/">ICCSI 2021</a></Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<ExperimentOutlined />} title="Workshops and Training">
          <Menu.Item key="WAT2023" icon={<ExperimentOutlined />}><a href="/iccsi2023/autumnSchoolCFP.html" >2023 AUTUMN SCHOOL</a></Menu.Item>
          <Menu.Item key="WAT2022" icon={<ExperimentOutlined />}><a href="/iccsi2022/autumnSchoolCFP.html" >2022 AUTUMN SCHOOL</a></Menu.Item>
          <Menu.Item key="WAT2021" icon={<ExperimentOutlined />}><a href="/iccsi2021/winterSchoolCFP.html" >2021 WINTER SCHOOL</a></Menu.Item>
        </SubMenu>
        <SubMenu key="sub3" icon={<PushpinOutlined />} title="Publications">
          <Menu.Item key="CPSI"  icon={<PushpinOutlined />}><a href="http://cpsi.agist.org" >Journal of Cyber-Physical-Social Intelligence</a></Menu.Item>
          <Menu.Item key="JOCACAC"  icon={<PushpinOutlined />}><a href="http://journalcc.net" >Journal of Command and Control</a></Menu.Item>
          
        </SubMenu>
        <Menu.Item key="4" icon={<ProjectOutlined />}>
          <Link to="news">NEWS</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<ContactsOutlined />}>
          <Link to="contact">CONTACTS</Link>
        </Menu.Item>

        
        
      </Menu>
      </div>
  </Header>
  )
}